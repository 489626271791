import { useCallback, useState } from "react";
import styled from "styled-components";
import {
  ApprovalDetailInformationEntity,
  ApprovalTemplateEntity,
  Approval_Sort,
  Approval_User_Type,
  IReceiveAndReferenceForDepartment,
  IReceiveAndReferenceForUser,
  ListOfApprovalTemporaryStorageEntity,
  useGetApprovalTemporaryStorageDetailInformationLazyQuery,
  useGetListOfApprovalBasicLineUserLazyQuery
} from "../../../generated/graphql";
import { useMemo } from "react";
import { useEffect } from "react";
import EChannel from "../../../Utils/EChannel/e-channel";
import ListOfApprovalHistory from "../select-approval-form-popup/list-of-approval-history";
import useOpenDialog from "../../../hooks/use-open-dialog";
import PopupController from "../popup-controller";
import ApprovalPopupButtons from "../approval-popup-buttons";
import ApprovalForm from "../approval-form/approval-form";
import { IApprovalFormFieldValue } from "../approval-form/types";
import { IEmployeeInformation } from "../approval-process/list-of-selected-approval-user";
import {
  IEmployeeInformationForReceiveAndReference,
  ReceiveAndReferenceType
} from "../approval-receive-and-reference-form/list-of-selected-approval-user-for-receive-and-reference-form";
import ApprovalDetailPopupStore from "../approval-detail-popup-store.ctx";
import { useController, useForm } from "react-hook-form";
import moment from "moment";
import { TOKEN } from "../../../apollo/apollo";
import { handleDecodedToken } from "../../../Utils/tokenMaker";
import { ApprovalType, IApprovalDetailMessage } from "../approval-detail-popup";
import { useLocation } from "react-router-dom";
import routes from "../../../routes";

interface Props {
  handleShowApprovalPopup?: (value: boolean) => void;
  newApprovalType?: ApprovalType;
  approvalTemporaryStorage?: ListOfApprovalTemporaryStorageEntity;
  handleSelectForm?: (isNext: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.grey};
`;

const ContentSection = styled(Container)`
  display: flex;
  flex: 20;
  flex-direction: column;
  gap: 10px;
  border: none;
  overflow: auto;
`;

function ApprovalRequestDetailPopup({
  newApprovalType,
  handleShowApprovalPopup,
  approvalTemporaryStorage,
  handleSelectForm
}: Props) {
  const location = useLocation();
  const [isReceiveAndReference, setIsReceiveAndReference] =
    useState<boolean>(false);
  const [approvalDetailInformation, setApprovalDetailInformation] =
    useState<ApprovalDetailInformationEntity>();

  const [approvalTemporaryStorageData, setApprovalTemporaryStorageData] =
    useState<ListOfApprovalTemporaryStorageEntity | undefined>(
      approvalTemporaryStorage
    );

  const [template, setTemplate] = useState<ApprovalTemplateEntity>();
  const [listOfUser, setListOfUser] = useState<IEmployeeInformation[]>([]);

  const [isTemporaryStorage, setIsTemporaryStorage] = useState<boolean>(false);
  const isAutoApproval = useMemo(() => {
    if (
      template?.approvalType === Approval_Sort.AutoApproval ||
      approvalTemporaryStorageData?.approvalType === Approval_Sort.AutoApproval
    ) {
      return true;
    }
    return false;
  }, [template, approvalTemporaryStorageData]);

  const [
    listOfUserForReceiveAndReference,
    setListOfUserForReceiveAndReference
  ] = useState<IEmployeeInformationForReceiveAndReference[]>([]);
  // 선택된 수신자 사용자 표시
  const [listOfReceiveUserName, setListOfReceiveUserName] = useState<string[]>(
    []
  );
  // 선택된 참조자 사용자 표시
  const [listOfReferenceUserName, setListOfReferenceUserName] = useState<
    string[]
  >([]);
  // 선택된 수신자 부서 표시
  const [listOfReceiveDepartmentName, setListOfReceiveDepartmentName] =
    useState<string[]>([]);
  // 선택된 참조자 부서 표시
  const [listOfReferenceDepartmentName, setListOfReferenceDepartmentName] =
    useState<string[]>([]);
  const [listOfReceiveUser, setListOfReceiveUser] = useState<
    IReceiveAndReferenceForUser[]
  >([]);
  const [listOfReceiveDepartment, setListOfReceiveDepartment] = useState<
    IReceiveAndReferenceForDepartment[]
  >([]);
  const [listOfReferenceUser, setListOfReferenceUser] = useState<
    IReceiveAndReferenceForUser[]
  >([]);
  const [listOfReferenceDepartment, setListOfReferenceDepartment] = useState<
    IReceiveAndReferenceForDepartment[]
  >([]);

  const [approvalType, setApprovalType] = useState<ApprovalType>(
    newApprovalType || ApprovalType.APPROVAL_WAITING_POPUP
  );

  // 임시 저장 상세 정보 가져오는 api
  const [
    getApprovalTemporaryStorageDetailInformation,
    { data: approvalTemStorageData }
  ] = useGetApprovalTemporaryStorageDetailInformationLazyQuery({
    onError(error) {
      console.log(error);
    }
  });

  const [getListOfApprovalBasicLineUser] =
    useGetListOfApprovalBasicLineUserLazyQuery({
      onError(error) {
        console.log(error);
      },
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        if (
          data?.getListOfApprovalBasicLineUser.ok &&
          data?.getListOfApprovalBasicLineUser.list &&
          !isAutoApproval
        ) {
          const newListOfUser: IEmployeeInformation[] =
            data?.getListOfApprovalBasicLineUser.list.map(item => {
              const parsedApprovalInfo = item.approvalInfo?.split("/");
              let name = "";
              let userPosition = "";
              let department = "";
              if (Array.isArray(parsedApprovalInfo)) {
                name = parsedApprovalInfo[0] ?? "";
                userPosition = parsedApprovalInfo[1] ?? "";
                department = parsedApprovalInfo[2] ?? "";
              }
              return {
                employeeId: item.employeeId,
                name,
                userPosition,
                department,
                approvalType: item.approvalType,
                checked: false
              };
            });
          setListOfUser(newListOfUser.reverse());
        }
      }
    });

  const { control, getValues, setValue } = useForm<IApprovalFormFieldValue>();

  const handleSubmit = useCallback(
    (handler: (data: IApprovalFormFieldValue) => void) => {
      const data = getValues();
      handler(data);
    },
    [getValues]
  );

  const { field: title } = useController({
    name: "title",
    defaultValue: "",
    control
  });

  const { field: startDate } = useController({
    name: "startDate",
    defaultValue: moment().format("YYYY-MM-DD"),
    control
  });

  const { field: endDate } = useController({
    name: "endDate",
    defaultValue: moment().format("YYYY-MM-DD"),
    control
  });

  const { field: startTime } = useController({
    name: "startTime",
    defaultValue: "00:00:00",
    control
  });
  const { field: endTime } = useController({
    name: "endTime",
    defaultValue: "23:59:59",
    control
  });

  const { field: description } = useController({
    name: "description",
    defaultValue: "",
    control
  });

  const isActiveApplicationButton = useMemo(() => {
    if (
      template?.approvalType === Approval_Sort.AutoApproval ||
      approvalTemporaryStorageData?.approvalType === Approval_Sort.AutoApproval
    ) {
      if (!title.value) {
        return true;
      }
      return false;
    }
    const listOfUserWithoutDrafter = listOfUser.filter(
      item => item.approvalType !== Approval_User_Type.Drafter
    );

    return listOfUserWithoutDrafter.length < 1 || !title.value;
  }, [listOfUser, title, template, approvalTemporaryStorageData]);

  const { isOpen, handleOpenDialog } = useOpenDialog();

  const employeeId = useMemo((): string => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const decodedData: any = handleDecodedToken(token);
      return decodedData.employee_id;
    }
    return "";
  }, []);

  const bc = useMemo(() => new BroadcastChannel(EChannel.APPROVAL), []);

  const handleIsReceiveAndReference = useCallback((value: boolean) => {
    setIsReceiveAndReference(value);
  }, []);

  const closeWindow = useCallback(() => {
    if (bc) {
      bc.close();
    }
    window.close();
  }, [bc]);

  useEffect(() => {
    const formTemplateIdx: number | undefined =
      approvalTemporaryStorageData?.formTemplateIdx ??
      template?.formTemplateIdx;
    if (bc && formTemplateIdx && !isReceiveAndReference) {
      bc.onmessage = event => {
        const message: IApprovalDetailMessage = event.data;
        if (
          message.type === ApprovalType.APPROVAL_PROCESS_POPUP &&
          message.formTemplateIdx === formTemplateIdx.toString()
        ) {
          setListOfUser(message.listOfEmployeeInformation ?? []);
        } else if (
          message.type ===
            ApprovalType.APPROVAL_PROCESS_POPUP_FOR_RECEIVE_REFERENCE &&
          message.formTemplateIdx === formTemplateIdx.toString()
        ) {
          setListOfUserForReceiveAndReference(
            message.listOfEmployeeInformationForReceiveAndReference ?? []
          );
        }
      };
    }
  }, [bc, approvalTemporaryStorageData, template, isReceiveAndReference]);

  useEffect(() => {
    if (bc && isReceiveAndReference) {
      bc.onmessage = event => {
        const message: IApprovalDetailMessage = event.data;
        if (message.type === ApprovalType.APPROVAL_RECEIVE_REFERENCE) {
          const newMessage: IApprovalDetailMessage = {
            type: ApprovalType.REQUEST_APPROVAL_RECEIVE_REFERENCE,
            listOfEmployeeInformationForReceiveAndReference:
              listOfUserForReceiveAndReference
          };
          bc.postMessage(newMessage);
          setIsReceiveAndReference(false);
        }
      };
    }
  }, [bc, isReceiveAndReference, listOfUserForReceiveAndReference]);

  useEffect(() => {
    if (bc && employeeId) {
      const newMessage: IApprovalDetailMessage = {
        type: ApprovalType.APPROVAL_REQUEST_POPUP
      };
      bc.postMessage(newMessage);
      bc.onmessage = event => {
        const message: IApprovalDetailMessage = event.data;
        if (message.type === ApprovalType.APPROVAL_REQUEST_POPUP) {
          if (message.template) {
            setTemplate(template => {
              if (!template) {
                setApprovalType(message.type);
                setIsTemporaryStorage(!!message.isTemporaryStorage);
                return message.template;
              } else {
                return template;
              }
            });
            getListOfApprovalBasicLineUser({
              variables: {
                employeeId
              }
            });
          }
          if (message.data) {
            setApprovalTemporaryStorageData(data => {
              if (!data) {
                setIsTemporaryStorage(!!message.isTemporaryStorage);
                return message.data as ListOfApprovalTemporaryStorageEntity;
              } else {
                return data;
              }
            });
          }
        }
      };
    }
    return () => {
      if (bc) {
        bc.postMessage("close");
        bc.close();
      }
    };
  }, [employeeId, bc]);

  useEffect(() => {
    if (listOfUserForReceiveAndReference) {
      const newListOfReceiveUser: IReceiveAndReferenceForUser[] = [];
      const newListOfReferenceUser: IReceiveAndReferenceForUser[] = [];
      const newListOfReceiveDepartment: IReceiveAndReferenceForDepartment[] =
        [];
      const newListOfReferenceDepartment: IReceiveAndReferenceForDepartment[] =
        [];
      const newListOfReceiveUserName: string[] = [];
      const newListOfReferenceUserName: string[] = [];
      const newListOfReceiveDepartmentName: string[] = [];
      const newListOfReferenceDepartmentName: string[] = [];
      listOfUserForReceiveAndReference.forEach(
        ({ employeeId, departmentId, type, name, department }) => {
          if (type === ReceiveAndReferenceType.RECEIVE) {
            if (employeeId) {
              newListOfReceiveUser.push({ employee_id: employeeId });
            }
            if (!employeeId && departmentId) {
              newListOfReceiveDepartment.push({
                department_id: parseInt(departmentId)
              });
            }
            if (name) {
              newListOfReceiveUserName.push(name);
            }
            if (!employeeId && department) {
              const departmentName = department.split(">").at(-1);
              newListOfReceiveDepartmentName.push(departmentName ?? "");
            }
          }
          if (type === ReceiveAndReferenceType.REFERENCE) {
            if (employeeId) {
              newListOfReferenceUser.push({ employee_id: employeeId });
            }
            if (!employeeId && departmentId) {
              newListOfReferenceDepartment.push({
                department_id: parseInt(departmentId)
              });
            }
            if (name) {
              newListOfReferenceUserName.push(name);
            }
            if (!employeeId && department) {
              const departmentName = department.split(">").at(-1);
              newListOfReferenceDepartmentName.push(departmentName ?? "");
            }
          }
        }
      );
      setListOfReceiveUser(newListOfReceiveUser);
      setListOfReferenceUser(newListOfReferenceUser);
      setListOfReceiveDepartment(newListOfReceiveDepartment);
      setListOfReferenceDepartment(newListOfReferenceDepartment);
      setListOfReceiveUserName(newListOfReceiveUserName);
      setListOfReferenceUserName(newListOfReferenceUserName);
      setListOfReceiveDepartmentName(newListOfReceiveDepartmentName);
      setListOfReferenceDepartmentName(newListOfReferenceDepartmentName);
    }
  }, [listOfUserForReceiveAndReference]);

  useEffect(() => {
    if (
      approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.ok
    ) {
      if (
        approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation
          .information
      ) {
        const information =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation
            .information;
        setApprovalDetailInformation(information);
        setValue("startDate", information.extendSDate || "");
        setValue("endDate", information.extendEDate || "");
        setValue(
          "startTime",
          information.extendSDateTime || moment().format("HH:mm")
        );
        setValue(
          "endTime",
          information.extendEDateTime || moment().format("HH:mm")
        );
        setValue("title", information.approvalTitle || "");
        setValue("description", information.temporaryExtendDescription || "");
      }
      if (
        approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation
          .listOfApprovalUser
      ) {
        const newListOfUser =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.listOfApprovalUser.map(
            item => {
              return {
                employeeId: item.approverId,
                userPosition: "",
                department: "",
                approvalType: item.approverType,
                checked: false,
                ...item
              };
            }
          );
        setListOfUser(newListOfUser);
      }
      if (
        approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation
          .listOfReceiveUser
      ) {
        const userName =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.listOfReceiveUser.map(
            item => item.employeeName
          );
        const newListOfReceiveUser =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.listOfReceiveUser.map(
            item => ({ employee_id: item.employeeId })
          );
        setListOfReceiveUser(newListOfReceiveUser);
        setListOfReceiveUserName(userName);
      }
      if (
        approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation
          .listOfReceiveDepartment
      ) {
        const listOfDepartmentName =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.listOfReceiveDepartment.map(
            item => item.departmentName
          );
        const newListOfReceiveDepartment =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.listOfReceiveDepartment.map(
            item => ({ department_id: item.departmentId })
          );
        setListOfReceiveDepartment(newListOfReceiveDepartment);
        setListOfReceiveDepartmentName(listOfDepartmentName);
      }
      if (
        approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation
          .listOfReferenceDepartment
      ) {
        const newListOfReferenceDepartmentName =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.listOfReferenceDepartment.map(
            item => item.departmentName
          );
        const newListOfReferenceDepartment =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.listOfReferenceDepartment.map(
            item => ({ department_id: item.departmentId })
          );

        setListOfReferenceDepartment(newListOfReferenceDepartment);
        setListOfReferenceDepartmentName(newListOfReferenceDepartmentName);
      }

      if (
        approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation
          .listOfReferenceUser
      ) {
        const newListOfReferenceDepartmentName =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.listOfReferenceUser.map(
            item => item.employeeName
          );
        const newListOfReferenceUser =
          approvalTemStorageData?.getApprovalTemporaryStorageDetailInformation.listOfReferenceUser.map(
            item => ({ employee_id: item.employeeId })
          );
        setListOfReferenceUser(newListOfReferenceUser);
        setListOfReferenceUserName(newListOfReferenceDepartmentName);
      }
    }
  }, [approvalTemStorageData]);

  useEffect(() => {
    if (employeeId && approvalTemporaryStorageData?.approvalRequestIdx) {
      getApprovalTemporaryStorageDetailInformation({
        variables: {
          employeeId,
          approvalRequestIdx: approvalTemporaryStorageData.approvalRequestIdx
        }
      });
    }
  }, [
    getApprovalTemporaryStorageDetailInformation,
    employeeId,
    approvalTemporaryStorageData
  ]);

  useEffect(() => {
    if (location.pathname === routes.pageRoutes.approvalTemporaryDocument) {
      setIsTemporaryStorage(true);
    }
  }, [location]);

  useEffect(() => {
    if (approvalTemporaryStorage) {
      setApprovalTemporaryStorageData(approvalTemporaryStorage);
    }
  }, [approvalTemporaryStorage]);

  return (
    <ApprovalDetailPopupStore.Provider
      value={{
        approvalRequestIdx: approvalTemporaryStorageData?.approvalRequestIdx,
        formTemplateIdx:
          approvalTemporaryStorageData?.formTemplateIdx ??
          template?.formTemplateIdx,
        listOfReceiveUser,
        listOfReceiveDepartment,
        listOfReferenceUser,
        listOfReferenceDepartment,
        listOfUser,
        isTemporaryStorage
      }}
    >
      <Container>
        {handleShowApprovalPopup && (
          <PopupController
            handleShowApprovalPopup={handleShowApprovalPopup}
            handleSelectForm={handleSelectForm}
          />
        )}
        <ApprovalPopupButtons
          handleShowApprovalPopup={handleShowApprovalPopup}
          approvalType={approvalType}
          handleOpenDialog={handleOpenDialog}
          isRequest={true}
          closeWindow={closeWindow}
          handleSubmit={handleSubmit}
          isActiveApplicationButton={isActiveApplicationButton}
          isAutoApproval={isAutoApproval}
        />
        <ContentSection>
          <ApprovalForm
            approvalTemporaryStorageData={approvalTemporaryStorageData}
            approvalType={approvalType}
            isRequest={true}
            template={template}
            listOfUser={listOfUser}
            handleIsReceiveAndReference={handleIsReceiveAndReference}
            listOfReferenceUserName={listOfReferenceUserName}
            listOfReceiveUserName={listOfReceiveUserName}
            title={title}
            startDate={startDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
            description={description}
            listOfReceiveDepartmentName={listOfReceiveDepartmentName}
            listOfReferenceDepartmentName={listOfReferenceDepartmentName}
            approvalDetailInformation={approvalDetailInformation}
          />
        </ContentSection>
        <ApprovalPopupButtons
          handleShowApprovalPopup={handleShowApprovalPopup}
          approvalType={approvalType}
          handleOpenDialog={handleOpenDialog}
          isRequest={true}
          closeWindow={closeWindow}
          handleSubmit={handleSubmit}
          isActiveApplicationButton={isActiveApplicationButton}
          isAutoApproval={isAutoApproval}
        />
        {handleShowApprovalPopup && (
          <PopupController handleShowApprovalPopup={handleShowApprovalPopup} />
        )}
        {isOpen && (
          <ListOfApprovalHistory
            handleClose={handleOpenDialog}
            approvalDetailInformation={approvalDetailInformation}
          />
        )}
      </Container>
    </ApprovalDetailPopupStore.Provider>
  );
}
export default ApprovalRequestDetailPopup;
