import { Approval_Status, SignType } from "../../../generated/graphql";
import {
  ApprovalListContainer,
  MainHead,
  Approver,
  Head,
  Body,
  StampImg,
  DisplaySign,
  DisplayName
} from "./styles";
import { IApprovalDataParams, useApprovalForm } from "./useApprovalForm";

export default function ApproverList({
  data,
  isRequest,
  template,
  listOfUser,
  startDate,
  approvalDetailInformation,
  approvalTemporaryStorageData
}: IApprovalDataParams) {
  const { drafter, createDate, listOfApprover, returnApprovalTypeString } =
    useApprovalForm({
      data,
      isRequest,
      template,
      listOfUser,
      startDate,
      approvalDetailInformation,
      approvalTemporaryStorageData
    });
  return (
    <ApprovalListContainer>
      <MainHead>
        <span>결</span>
        <span>재</span>
      </MainHead>
      <Approver>
        <Head>기안자</Head>
        <Body>
          {drafter.signType === SignType.Image && drafter.signImage && (
            <StampImg
              src={
                drafter.signImage.includes("base64")
                  ? drafter.signImage
                  : `data:image/png;base64, ${drafter.signImage}`
              }
              alt="서명"
            />
          )}
          {drafter.signType === SignType.Text && drafter.signText && (
            <DisplaySign>{drafter.signText}</DisplaySign>
          )}
          <DisplayName>{drafter.name}</DisplayName>
          {drafter.approvalTime && <span>{`(${drafter.approvalTime})`}</span>}
          {!drafter.approvalTime && createDate && (
            <span>{`${createDate}`}</span>
          )}
        </Body>
      </Approver>
      {listOfApprover &&
        listOfApprover.map((item, index) => {
          const approvalType = item.approvalType;
          const signType = item.signType;
          const signImage = item.signImage;
          const signText = item.signText;
          let name = item.name;
          const approvalTime = item.approvalTime
            ? item.approvalTime.split(" ").at(0)?.split("-").slice(1).join("/")
            : "";
          let isReturn = false;
          if (name.length > 10) {
            name = name.slice(0, 9);
            name += "...";
          }

          if (item.approvalResult === Approval_Status.Return) {
            isReturn = true;
          }
          return (
            <Approver key={index}>
              <Head>
                {returnApprovalTypeString(
                  approvalType,
                  listOfApprover.length - 1 === index
                )}
              </Head>
              <Body>
                {signType === SignType.Image &&
                  signImage &&
                  item.approvalResult &&
                  !isReturn && (
                    <StampImg
                      src={
                        signImage.includes("base64")
                          ? signImage
                          : `data:image/png;base64, ${signImage}`
                      }
                      alt="서명"
                    />
                  )}
                {signType === SignType.Text &&
                  item.approvalResult &&
                  signText &&
                  !isReturn && <DisplaySign>{signText}</DisplaySign>}
                {isReturn && (
                  <DisplaySign isReturn={isReturn}>{`반려`}</DisplaySign>
                )}
                <DisplayName>{name}</DisplayName>
                {approvalTime && <span>{`(${approvalTime})`}</span>}
              </Body>
            </Approver>
          );
        })}
    </ApprovalListContainer>
  );
}
