import moment from "moment";
import { ChangeEvent, useState } from "react";
import { ControllerRenderProps } from "react-hook-form";
import {
  ApprovalDetailInformationEntity,
  ApprovalEntity,
  ApprovalTemplateEntity,
  ListOfApprovalTemporaryStorageEntity
} from "../../../generated/graphql";
import routes from "../../../routes";
import Button from "../../globalComponents/Button";
import StyleInput from "../../inputs/style-input";
import StyleTextarea from "../../inputs/style-textarea";
import TextInput from "../../inputs/text-input";
import { ApprovalType } from "../approval-detail-popup";
import { IEmployeeInformation } from "../approval-process/list-of-selected-approval-user";
import { IApprovalFormFieldValue } from "./types";
import {
  Container,
  Content,
  Description,
  DisplayUsers,
  InputContainer,
  Label,
  MainSection,
  Subscription
} from "./styles";
import { useApprovalForm } from "./useApprovalForm";
import SSelect from "../../shared/s_select/s_select";
import ApproverList from "./ApproverList";
import ApprovalFormTitle from "./ApprovalFormTitle";

interface IProps {
  approvalTemporaryStorageData?: ListOfApprovalTemporaryStorageEntity;
  data?: ApprovalEntity;
  approvalType?: ApprovalType;
  isRequest?: boolean;
  template?: ApprovalTemplateEntity;
  handleIsReceiveAndReference?: (value: boolean) => void;
  listOfUser?: IEmployeeInformation[];
  listOfReferenceUserName: string[];
  listOfReceiveUserName: string[];
  listOfReceiveDepartmentName: string[];
  listOfReferenceDepartmentName: string[];
  title: ControllerRenderProps<IApprovalFormFieldValue, "title">;
  startDate: ControllerRenderProps<IApprovalFormFieldValue, "startDate">;
  endDate: ControllerRenderProps<IApprovalFormFieldValue, "endDate">;
  startTime: ControllerRenderProps<IApprovalFormFieldValue, "startTime">;
  endTime: ControllerRenderProps<IApprovalFormFieldValue, "endTime">;
  description: ControllerRenderProps<IApprovalFormFieldValue, "description">;
  approvalDetailInformation?: ApprovalDetailInformationEntity;
}
export interface IApprovalDetailMessage {
  type: ApprovalType;
  data: ApprovalEntity;
}

type NumOrStr = string | number;
interface Option {
  value: NumOrStr;
  name?: string;
}

function ApprovalForm({
  data,
  isRequest,
  template,
  handleIsReceiveAndReference,
  listOfReceiveUserName,
  listOfReferenceUserName,
  listOfReceiveDepartmentName,
  listOfReferenceDepartmentName,
  title,
  startDate,
  endDate,
  startTime,
  endTime,
  description,
  approvalDetailInformation,
  approvalTemporaryStorageData,
  listOfUser = []
}: IProps) {
  const {
    formName,
    user,
    isActiveTime,
    annualInfo,
    departmentName,
    formTemplateIdx,
    isActiveWorkType, // 근무제 활성화 여부
    workingTemplateOptions
  } = useApprovalForm({
    data,
    isRequest,
    template,
    listOfUser,
    startDate,
    approvalDetailInformation,
    approvalTemporaryStorageData
  });

  const [selectedWorkingTemplateIndex, setSelectedWorkingTemplateIndex] =
    useState<number>(0);

  const renderSSelect = (
    id: string,
    value: string | number,
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void,
    options: Option[] | string[]
  ) => (
    <SSelect id={id} value={value} onChange={onChange}>
      {options.map(item => {
        const option = item as Option;
        const optionValue = option.value === undefined ? item : option.value;
        const optionName = option.name === undefined ? item : option.name;
        return (
          <option value={optionValue as NumOrStr} key={optionValue as NumOrStr}>
            {optionName}
          </option>
        );
      })}
    </SSelect>
  );

  return (
    <Container>
      <ApproverList
        data={data}
        isRequest={isRequest}
        template={template}
        listOfUser={listOfUser}
        startDate={startDate}
        approvalDetailInformation={approvalDetailInformation}
        approvalTemporaryStorageData={approvalTemporaryStorageData}
      />
      <ApprovalFormTitle
        template={template}
        approvalDetailInformation={approvalDetailInformation}
        formName={formName ?? ""}
      />
      <MainSection $annualInfo={annualInfo} $isRequest={isRequest}>
        <Content>
          <Label isSpacing>{`소   속`}</Label>
          <Subscription>{departmentName}</Subscription>
        </Content>
        <Content>
          <Label isSpacing>{`이   름`}</Label>
          <Subscription>
            {isRequest
              ? user?.name ?? ""
              : approvalDetailInformation?.employeeName ?? ""}
          </Subscription>
        </Content>
        <Content>
          <Label isSpacing>{`직   급`}</Label>
          <Subscription>
            {isRequest
              ? user?.userPosition ?? ""
              : approvalDetailInformation?.userPosition ?? ""}
          </Subscription>
        </Content>
        <Content>
          <Label>문서번호</Label>
          <Subscription>{data?.docNumber}</Subscription>
        </Content>
        <Content>
          <Label>{`작 성 일`}</Label>
          <Subscription>
            {approvalDetailInformation?.signUpDateTimeTemp ||
              moment().format("YYYY-MM-DD")}
          </Subscription>
        </Content>
        <Content>
          <Label>정보구분</Label>
          <Subscription>
            {template?.name ?? approvalDetailInformation?.categoryName}
          </Subscription>
        </Content>
        <Content>
          <Label>관리이름</Label>
          <Subscription>
            {template?.templateTitle ??
              approvalDetailInformation?.formTemplateTitle}
          </Subscription>
        </Content>
        <Content>
          <Label>시작일자</Label>
          {isRequest ? (
            <InputContainer>
              <TextInput type="date" {...startDate} />
            </InputContainer>
          ) : (
            <Subscription>{data?.extendStartDate}</Subscription>
          )}
        </Content>
        <Content>
          <Label>종료일자</Label>
          {isRequest ? (
            <InputContainer>
              <TextInput type="date" {...endDate} />
            </InputContainer>
          ) : (
            <Subscription>{data?.extendEndDate}</Subscription>
          )}
        </Content>
        <Content>
          <Label>시작시간</Label>
          <Subscription>
            {data?.extendStartDateTime ?? (
              <TextInput type="time" {...startTime} disabled={isActiveTime} />
            )}
          </Subscription>
        </Content>
        <Content>
          <Label>종료시간</Label>
          <Subscription>
            {data?.extendEndDateTime ?? (
              <TextInput type="time" {...endTime} disabled={isActiveTime} />
            )}
          </Subscription>
        </Content>
        {annualInfo && (
          <Content>
            <Label>{`총 발생일`}</Label>
            <Subscription>{annualInfo?.totDay ?? ""}</Subscription>
          </Content>
        )}
        {annualInfo && (
          <Content>
            <Label>{`잔여일`}</Label>
            <Subscription>{annualInfo?.leaveDay}</Subscription>
          </Content>
        )}
        {annualInfo && (
          <Content>
            <Label>{`사용일`}</Label>
            <Subscription>{annualInfo?.useDay}</Subscription>
          </Content>
        )}
        {isActiveWorkType && (
          <Content style={{ gridColumn: "span 3", gridRow: "" }}>
            <Label>{`근무제`}</Label>
            {renderSSelect(
              "underAgeWorkScheduleForApproval",
              selectedWorkingTemplateIndex,
              event => {
                // TODO: 미성년자 근무표를 저장하고 사용하지 않는다. 쿼리 정의 필요?
                setSelectedWorkingTemplateIndex(Number(event.target.value));
              },
              workingTemplateOptions as Option[]
            )}
          </Content>
        )}
        <Content style={{ gridColumn: "span 3", gridRow: "" }}>
          <Label isSpacing>{`제   목`}</Label>
          <Subscription>
            {isRequest ? (
              <StyleInput
                {...title}
                minWidth="300px"
                maxWidth="100%"
                $width="100%"
              />
            ) : (
              <span>{title.value}</span>
            )}
          </Subscription>
        </Content>
        <Content style={{ gridColumn: "span 3", gridRow: "span 5" }}>
          {approvalDetailInformation?.extendDescription && (
            <Description>
              {approvalDetailInformation?.extendDescription}
            </Description>
          )}
          {isRequest && <StyleTextarea {...description} />}
        </Content>
        <Content style={{ gridColumn: "span 3", gridRow: "" }}>
          <Label isSpacing>{`수   신`}</Label>
          <Subscription>
            {isRequest && (
              <Button
                customMinHeight="20px"
                customMinWidth="50px"
                onClick={() => {
                  if (handleIsReceiveAndReference) {
                    handleIsReceiveAndReference(true);
                  }
                  window.open(
                    `${routes.pageRoutes.approvalProcess}${formTemplateIdx}`,
                    "",
                    "width=960, height=650"
                  );
                }}
              >
                수신선택
              </Button>
            )}
            <DisplayUsers>
              {listOfReceiveDepartmentName.map((item, index) => (
                <span key={`${index} ${item}`}>
                  {listOfReceiveDepartmentName.length - 1 === index
                    ? listOfReceiveUserName.length > 0
                      ? `${item},`
                      : item
                    : `${item},`}
                </span>
              ))}
              {listOfReceiveUserName.map((item, index) => (
                <span key={`${item} ${index}`}>
                  {listOfReceiveUserName.length - 1 === index
                    ? item
                    : `${item},`}
                </span>
              ))}
            </DisplayUsers>
          </Subscription>
        </Content>
        <Content style={{ gridColumn: "span 3", gridRow: "" }}>
          <Label isSpacing>{`참   조`}</Label>
          <Subscription>
            {isRequest && (
              <Button
                customMinHeight="20px"
                customMinWidth="50px"
                onClick={() => {
                  if (handleIsReceiveAndReference) {
                    handleIsReceiveAndReference(true);
                  }
                  window.open(
                    `${routes.pageRoutes.approvalProcess}${formTemplateIdx}`,
                    "",
                    "width=960, height=650"
                  );
                }}
              >
                참조선택
              </Button>
            )}
            <DisplayUsers>
              {listOfReferenceDepartmentName.map((item, index) => (
                <span key={`${index} ${item}`}>
                  {listOfReferenceDepartmentName.length - 1 === index
                    ? listOfReferenceUserName.length > 0
                      ? `${item},`
                      : item
                    : `${item},`}
                </span>
              ))}
              {listOfReferenceUserName.map((item, index) => (
                <span key={`${index} ${item}`}>
                  {listOfReferenceUserName.length - 1 === index
                    ? item
                    : `${item},`}
                </span>
              ))}
            </DisplayUsers>
          </Subscription>
        </Content>
        <Content style={{ gridColumn: "span 3", gridRow: "" }}>
          {/* <Label>첨부파일</Label> */}
          {/* <Subscription></Subscription> */}
        </Content>
      </MainSection>
    </Container>
  );
}

export default ApprovalForm;
