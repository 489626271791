import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../../apollo/apollo-var";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Approval_User_Type,
  ApprovalAdditionalFieldEntity,
  ApprovalDetailInformationEntity,
  ApprovalEntity,
  ApprovalTemplateEntity,
  ListOfApprovalTemporaryStorageEntity,
  SignType,
  useGetAdditionalFieldInApprovalFormLazyQuery,
  useGetApprovalPasswordAndSignSettingInfoLazyQuery,
  useGetListAllWorkingTemplateLazyQuery,
  useGetUserLazyQuery,
  UserEntity
} from "../../../generated/graphql";
import { ApprovalType } from "../approval-detail-popup";
import { IEmployeeInformation } from "../approval-process/list-of-selected-approval-user";
import { ControllerRenderProps } from "react-hook-form";
import { IApprovalFormFieldValue } from "./types";
import { TOKEN } from "../../../apollo/apollo";
import { handleDecodedToken } from "../../../Utils/tokenMaker";
import moment from "moment";
import { LABELS } from "../../../screens/view-settings/work-schedule-settings/add-update/top-tabs/additional-setting-tab/strings";

export interface IApprovalDataParams {
  approvalTemporaryStorageData?: ListOfApprovalTemporaryStorageEntity;
  data?: ApprovalEntity;
  isRequest?: boolean;
  template?: ApprovalTemplateEntity;
  listOfUser?: IEmployeeInformation[];
  startDate: ControllerRenderProps<IApprovalFormFieldValue, "startDate">;
  approvalDetailInformation?: ApprovalDetailInformationEntity;
}

export function useApprovalForm(params: IApprovalDataParams) {
  const {
    template,
    data,
    approvalTemporaryStorageData,
    listOfUser,
    isRequest,
    startDate,
    approvalDetailInformation
  } = params;

  const fName = useReactiveVar(listOfApolloVar.approvalFormNameVar);
  const formName = useMemo(() => {
    if (template?.formIdx) {
      return fName.get(template?.formIdx);
    }
    if (data?.formIdx) {
      return fName.get(data?.formIdx);
    }
    if (approvalTemporaryStorageData?.formIdx) {
      return fName.get(approvalTemporaryStorageData.formIdx);
    }
    return "";
  }, [template, data, approvalTemporaryStorageData]);

  const [user, setUser] = useState<UserEntity>();

  const [signImage, setSignImage] = useState<string | null>();
  const [signText, setSignText] = useState<string | null>();
  const [signType, setSignType] = useState<SignType | null>();
  const [isActiveTime, setIsActiveTime] = useState(true);
  // 근무제 활성화 여부
  const [isActiveWorkType, setIsActiveWorkType] = useState(false);

  const [
    getApprovalPasswordAndSignSettingInfo,
    { data: approvalPasswordAndSignSettingInfo }
  ] = useGetApprovalPasswordAndSignSettingInfoLazyQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
    }
  });

  const [getAdditionalFieldInApprovalForm, { data: additionalFieldData }] =
    useGetAdditionalFieldInApprovalFormLazyQuery({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
      }
    });

  // 미성년자 근무표 조회
  const [getListWorkingTemplate, { data: workingTemplateData }] =
    useGetListAllWorkingTemplateLazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  const workingTemplateOptions = useMemo(() => {
    return [
      { value: 0, name: LABELS.NOT_USE_KO },
      ...(workingTemplateData?.getListAllWorkingTemplate?.list?.map(item => ({
        value: item.workingTemplateIdx,
        name: item.name
      })) ?? [])
    ];
  }, [workingTemplateData]);

  function isDrafterSelected(listOfUser?: IEmployeeInformation[]) {
    return (
      listOfUser && listOfUser[0]?.approverType === Approval_User_Type.Drafter
    );
  }

  const departmentName: string = useMemo(() => {
    const newDepartmentName = user?.fullDepartmentName?.split(">").at(-1);
    return newDepartmentName || "";
  }, [user?.fullDepartmentName]);

  const returnApprovalTypeString = useCallback(
    (type: Approval_User_Type, isLast: boolean) => {
      if (type === Approval_User_Type.Agreement) {
        return "합의";
      }
      if (type === Approval_User_Type.Approval) {
        return "결재";
      }
    },
    []
  );

  const createDate: string = useMemo(() => {
    let date = "";
    if (approvalDetailInformation?.signUpDateTimeTemp) {
      date = moment(approvalDetailInformation?.signUpDateTimeTemp).format(
        "(MM/DD)"
      );
    }

    return date;
  }, [approvalDetailInformation?.signUpDateTimeTemp]);

  const formTemplateIdx = useMemo(() => {
    const idx =
      data?.formTemplateIdx ??
      template?.formTemplateIdx ??
      approvalTemporaryStorageData?.formTemplateIdx;
    if (idx) {
      return `:${idx}`;
    }
    return "";
  }, [data, template, approvalTemporaryStorageData]);

  const annualInfo = useMemo<ApprovalAdditionalFieldEntity | undefined>(() => {
    if (data?.annualInfo) {
      try {
        const jsonParse = JSON.parse(data?.annualInfo);
        return {
          totDay: jsonParse?.tot_day ?? "",
          useDay: jsonParse?.use_day ?? "",
          leaveDay: jsonParse?.leave_day ?? ""
        };
      } catch (error) {
        console.error("Invalid JSON in annualInfo:", error);
        return undefined;
      }
    }
    if (
      additionalFieldData?.getAdditionalFieldInApprovalForm.ok &&
      additionalFieldData?.getAdditionalFieldInApprovalForm.info
    ) {
      return additionalFieldData?.getAdditionalFieldInApprovalForm.info;
    }
    return undefined;
  }, [data, additionalFieldData]);

  const drafter = useMemo(() => {
    let newDrafter = {
      employeeId: user?.employeeId ?? "",
      name: user?.name ?? "",
      signImage,
      signText,
      signType,
      approvalTime: ""
    };
    if (isDrafterSelected(listOfUser)) {
      newDrafter = {
        employeeId: listOfUser![0].employeeId ?? "",
        name: listOfUser![0].name,
        signImage: listOfUser![0].signImage,
        signText: listOfUser![0].signText,
        signType: listOfUser![0].signType,
        approvalTime: listOfUser![0]?.approvalTime ?? ""
      };
    }
    if (newDrafter.name.length > 10) {
      let name = newDrafter.name;
      name = name.slice(0, 9);
      name += "...";
      newDrafter.name = name;
    }
    return newDrafter;
  }, [listOfUser, signImage, signText, signType, user]);

  const listOfApprover = useMemo(() => {
    return listOfUser?.filter(
      item => item.approverType !== Approval_User_Type.Drafter
    );
  }, [listOfUser]);

  const [getUser, { data: userData }] = useGetUserLazyQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
    }
  });

  const handleIsActiveTime = useCallback(() => {
    const initFormIdx = 0;
    const formIdx: number =
      template?.formIdx ??
      data?.formIdx ??
      approvalTemporaryStorageData?.formIdx ??
      initFormIdx;
    const currentFormName = fName.get(formIdx);
    if ([fName.get(3), fName.get(4), fName.get(5)].includes(currentFormName)) {
      setIsActiveTime(false);
    }
  }, [template, data, approvalTemporaryStorageData, fName]);

  const handleIsActiveWorkType = useCallback(() => {
    const initFormIdx = 0;
    const formIdx: number =
      template?.formIdx ??
      data?.formIdx ??
      approvalTemporaryStorageData?.formIdx ??
      initFormIdx;
    if (formIdx === 10) {
      setIsActiveWorkType(true);
    } else {
      setIsActiveWorkType(false);
    }
  }, [template, data, approvalTemporaryStorageData]);

  useEffect(() => {
    getListWorkingTemplate({
      variables: {
        searchValue: ""
      }
    });
  }, [getListWorkingTemplate]);

  // useEffect 함수
  useEffect(() => {
    handleIsActiveTime();
    handleIsActiveWorkType();
  }, [handleIsActiveTime, handleIsActiveWorkType]);

  useEffect(() => {
    if (
      approvalPasswordAndSignSettingInfo?.getApprovalPasswordAndSignSettingInfo
        .info
    ) {
      const { signType, signImage, signText } =
        approvalPasswordAndSignSettingInfo
          ?.getApprovalPasswordAndSignSettingInfo.info;
      setSignType(signType);
      if (signType === SignType.Image) {
        setSignImage(signImage);
      } else {
        setSignText(signText);
      }
    }
  }, [approvalPasswordAndSignSettingInfo]);

  useEffect(() => {
    if (userData?.getUser.user) {
      setUser(userData?.getUser.user[0]);
    }
  }, [userData]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN);
    if (isDrafterSelected(listOfUser)) {
      const newDrafter = {
        employeeId: listOfUser![0].employeeId,
        name: listOfUser![0].name,
        signImage: listOfUser![0].signImage,
        signText: listOfUser![0].signText,
        signType: listOfUser![0].signType,
        approvalTime: listOfUser![0]?.approvalTime ?? ""
      };
      getUser({
        variables: {
          getUserId: newDrafter.employeeId,
          like: false
        }
      });
      getApprovalPasswordAndSignSettingInfo({
        variables: {
          employeeId: newDrafter.employeeId
        }
      });
    } else if (token) {
      const decodedData: any = handleDecodedToken(token);
      getUser({
        variables: {
          getUserId: decodedData.employee_id,
          like: false
        }
      });
      getApprovalPasswordAndSignSettingInfo({
        variables: {
          employeeId: decodedData.employee_id
        }
      });
    }
  }, [getUser, getApprovalPasswordAndSignSettingInfo, listOfUser]);

  useEffect(() => {
    if (template?.categoryId && isRequest && drafter.employeeId) {
      getAdditionalFieldInApprovalForm({
        variables: {
          employeeId: drafter.employeeId,
          year: startDate.value.slice(0, 4),
          categoryId: template?.categoryId
        }
      });
    }
  }, [
    getAdditionalFieldInApprovalForm,
    startDate,
    template?.categoryId,
    drafter
  ]);

  return {
    drafter,
    formName,
    user,
    createDate,
    isActiveTime,
    annualInfo,
    listOfApprover,
    returnApprovalTypeString,
    departmentName,
    formTemplateIdx,
    isActiveWorkType,
    workingTemplateOptions
  };
}
